// scss-lint:disable VendorPrefix, Indentation
$bg: #616161;
$text-light: #fafafa;
$text: #212121;
$placeholder: #999;
$panel-bg: #151515;
$shadow-dark: rgba(0, 0, 0, 0.65);
$button: #2d388a;
$button-hover: #4256d6;
$button-active: #fafafa;

//MIXINS
@mixin transition($time) {
  -webkit-transition: all $time;
  transition: all $time;
}

.sign-up-container {
  height: 90vh;
  background: url("../../../public/faqpage.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10%;
}

a {
  @include transition(0.4s);
  color: $button-active;
  text-decoration: none;

  &:hover {
    color: $button-hover;
    //font-weight: bold;
  }
}

.sign-up-panel {
  @include transition(0.7s);
  // font-family: "Open Sans", sans-serif;

  background: $panel-bg;
  border-radius: 5px;
  box-shadow: 0 5px 20px $shadow-dark;
  color: $text;
  left: 0;
  width: 600px;
  margin: 0 auto 150px;
  overflow: hidden;
  position: relative;
  right: 0;
  height: 37rem;
  z-index: 200;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .sign-up-h2-header {
    color: black;
    width: 22rem;
    /* height: 5rem; */
    font-size: 1.5rem;
    background: #5485b1;
    margin: 0 auto;
    border-radius: 0.5rem;
  }
  button {
    @include transition(0.7s);
    background: $button;
    border: 0;
    border-radius: 6px;
    color: $text;
    cursor: pointer;
    display: block;
    font-size: 1em;
    font-weight: 300;
    margin: 20px auto;
    padding: 10px;
    width: 330px;

    &:active {
      background: $button-active;
      color: $text;
    }

    &:hover {
      @include transition(0.55s);
      background: $button-hover;
      box-shadow: 0 2px 8px $shadow-dark;
      color: $panel-bg;
      text-shadow: 0 0 1px $shadow-dark;
    }
  }

  &:hover {
    @include transition(0.7s);
    border-radius: 35px 35px 35px 5px;
    box-shadow: 0 2px 5px $shadow-dark;
  }
}

.sign-up-panel-group {
  margin: 20px auto;
  position: relative;
  width: 90%;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sign-up-input {
  border: 0;
  border-bottom: 1px solid $text;
  display: block;
  font-size: 1em;
  padding: 10px;

  &:focus {
    outline: 0;
  }
}

label {
  @include transition(0.2s);
  color: $placeholder;
  font-size: 14px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

//animate label when input is in focus
.sign-up-input:focus ~ label,
.sign-up-input:valid ~ label {
  color: $button-hover;
  font-size: 0.75em;
  font-weight: bold;
  top: -20px;
}

//animate bottom border when input is in focus*/
.sign-up-border {
  display: block;
  position: relative;
  width: 315px;
}

.sign-up-border::before,
.sign-up-border::after {
  @include transition(0.5s);
  background: $button-active;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.sign-up-border::before {
  left: 50%;
}

.sign-up-border::after {
  right: 50%;
}

//border width when clicked*/
.sign-up-input:focus ~ .border::before,
.sign-up-input:focus ~ .border::after {
  width: 50%;
}

.sign-up-panel-footer {
  font-size: 1.2em;
}

footer {
  bottom: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.sign-up-follow {
  margin: 0 auto;
}
.sign-up-form {
  margin: 0 auto;
  min-width: 420px;
}

@media only screen and (max-width: 667px) {
  .sign-up-form {
    min-width: 0px;
    // margin-left: 11%;
  }
  .sign-up-container {
    padding-top: 25%;
    padding-bottom: 15%;

    .sign-up-panel {
      margin: 0 auto;
      padding-top: 40px;
      background: $panel-bg;
      border-radius: 2px;
      box-shadow: 0 5px 20px $shadow-dark;
      color: $text;
      left: 0;
      width: 90%;
      text-align: center;
      margin-bottom: 100px;
      margin-top: 50px;
      height: 70%;
      padding-bottom: 20px;
      // justify-content: center;
      // align-items: center;

      button {
        @include transition(0.7s);
        background: $button;
        border: 0;
        border-radius: 2px;
        color: $text;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 300;
        // margin: 20px auto;
        // margin-left: 3%;
        padding: 10px;
        width: 60%;
      }

      &:hover {
        @include transition(0.7s);
        border-radius: 35px 35px 35px 5px;
        box-shadow: 0 2px 5px $shadow-dark;
      }
    }
    .sign-up-panel-group {
      margin-bottom: 2rem;
      font-size: 1.5em;
      // margin-left: 1rem;
    }

    .sign-up-input {
      border-bottom: 1px solid $text;
      font-size: 1em;
      // margin: 0 auto;

      &:focus {
        border-bottom: none;
      }
    }

    label {
      font-size: 14px;
    }

    a {
      font-size: 16px;
    }
  }
  .sign-up-panel-footer p {
    font-size: 12px;
    text-align: center;
  }
}
